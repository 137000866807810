import {
    ApplicationName,
    ApplicationPaths,
} from "../constants/api-authorization";
import {
    SignoutPopupArgs,
    User,
    UserManager,
    WebStorageStateStore,
} from "oidc-client-ts";

export class AuthorizeService {
    _callbacks: Array<{ subscription: number; callback: () => void }> = [];
    _nextSubscriptionId = 0;
    _user: User | null = null;
    _isAuthenticated = false;

    // By default pop ups are disabled because they don't work properly on Edge.
    // If you want to enable pop up authentication simply set this flag to false.
    _popUpDisabled = true;

    _userManager: UserManager | undefined = undefined;
    getCurrentTenant() { }

    async isAuthenticated() {
        const user = await this.getUser();
        return !!user;
    }

    async getUser() {
        if (this._user && this._user.profile) {
            return this._user.profile;
        }

        await this.ensureUserManagerInitialized();
        if (!this._userManager) return null;
        const user = await this._userManager.getUser();
        return user && user.profile;
    }

    async getAccessToken() {
        await this.ensureUserManagerInitialized();
        if (!this._userManager) return null;
        const user = await this._userManager.getUser();
        return user && user.access_token;
    }

    // We try to authenticate the user in three different ways:
    // 1) We try to see if we can authenticate the user silently. This happens
    //    when the user is already logged in on the IdP and is done using a hidden iframe
    //    on the client.
    // 2) We try to authenticate the user using a PopUp Window. This might fail if there is a
    //    Pop-Up blocker or the user has disabled PopUps.
    // 3) If the two methods above fail, we redirect the browser to the IdP to perform a traditional
    //    redirect flow.
    async signIn(state: unknown) {
        await this.ensureUserManagerInitialized();
        try {
            if (!this._userManager) {
                console.log("this._userManager", this._userManager);
                return null;
            }
            const silentUser = await this._userManager.signinSilent(
                this.createArguments()
            );
            this.updateState(silentUser);
            return this.success(state);
        } catch (silentError) {
            // User might not be authenticated, fallback to popup authentication
            console.log("Silent authentication error: ", silentError);

            try {
                if (this._popUpDisabled) {
                    throw new Error(
                        "Popup disabled. Change 'AuthorizeService.js:AuthorizeService._popupDisabled' to false to enable it."
                    );
                }
                const popUpUser = await this._userManager!.signinPopup(
                    this.createArguments()
                );
                this.updateState(popUpUser);
                return this.success(state);
            } catch (popUpError) {
                if (
                    popUpError instanceof Error &&
                    popUpError.message === "Popup window closed"
                ) {
                    // The user explicitly cancelled the login action by closing an opened popup.
                    return this.error("The user closed the window.");
                } else if (!this._popUpDisabled) {
                    console.log("Popup authentication error: ", popUpError);
                }

                // PopUps might be blocked by the user, fallback to redirect
                try {
                    await this._userManager!.signinRedirect(this.createArguments(state));
                    return this.redirect();
                } catch (redirectError) {
                    console.log("Redirect authentication error: ", redirectError);
                    return this.error(`Redirect authentication error: ${redirectError}`);
                }
            }
        }
    }

    async completeSignIn(url: string) {
        try {
            await this.ensureUserManagerInitialized();
            if (!this._userManager) return;
            const user = await this._userManager.signinCallback(url);
            if (user) {
                this.updateState(user);
                return this.success(user && user.state);
            } else {
                this.updateState(null);
                return this.error("User data not present");
            }
        } catch (error) {
            console.log("There was an error signing in: ", error);
            return this.error("There was an error signing in.");
        }
    }

    // We try to sign out the user in two different ways:
    // 1) We try to do a sign-out using a PopUp Window. This might fail if there is a
    //    Pop-Up blocker or the user has disabled PopUps.
    // 2) If the method above fails, we redirect the browser to the IdP to perform a traditional
    //    post logout redirect flow.
    async signOut(state: unknown) {
        if (!this._userManager) return;
        await this.ensureUserManagerInitialized();
        try {
            if (this._popUpDisabled) {
                throw new Error(
                    "Popup disabled. Change 'AuthorizeService.js:AuthorizeService._popupDisabled' to false to enable it."
                );
            }

            await this._userManager.signoutPopup(this.createArguments());
            this.updateState(null);
            return this.success(state);
        } catch (popupSignOutError) {
            console.log("Popup signout error: ", popupSignOutError);
            try {
                await this._userManager.signoutRedirect(this.createArguments(state));
                return this.redirect();
            } catch (redirectSignOutError) {
                console.log("Redirect signout error: ", redirectSignOutError);
                return this.error(
                    `There was an redirect signout error '${redirectSignOutError}'.`
                );
            }
        }
    }

    async completeSignOut(url: string) {
        await this.ensureUserManagerInitialized();
        try {
            if (!this._userManager) throw new Error("USERMANAGER_IS_UNDEFINED");
            await this._userManager.signoutCallback(url);
            this.updateState(null);
            return this.success("signoutCallback success");
        } catch (error) {
            console.log(`There was an error trying to log out '${error}'.`);
            return this.error(`There was an error trying to log out '${error}'.`);
        }
    }

    updateState(user: User | null) {
        this._user = user;
        this._isAuthenticated = !!this._user;
        this.notifySubscribers();
    }

    subscribe(callback: () => void) {
        this._callbacks.push({
            callback,
            subscription: this._nextSubscriptionId++,
        });
        return this._nextSubscriptionId - 1;
    }

    unsubscribe(subscriptionId: number) {
        const subscriptionIndex = this._callbacks
            .map((element, index) =>
                element.subscription === subscriptionId
                    ? { found: true, index }
                    : { found: false }
            )
            .filter((element) => element.found === true);
        if (subscriptionIndex.length !== 1) {
            throw new Error(
                `Found an invalid number of subscriptions ${subscriptionIndex.length}`
            );
        }

        if (subscriptionIndex[0].index)
            this._callbacks.splice(subscriptionIndex[0].index, 1);
    }

    notifySubscribers() {
        for (let i = 0; i < this._callbacks.length; i++) {
            const callback = this._callbacks[i].callback;
            callback();
        }
    }

    createArguments(state: unknown = null) {
        return { useReplaceToNavigate: true, data: state } as SignoutPopupArgs;
    }

    error(message: string) {
        return { status: AuthenticationResultStatus.Fail, message };
    }

    success(state: unknown) {
        return { status: AuthenticationResultStatus.Success, state };
    }

    redirect() {
        return { status: AuthenticationResultStatus.Redirect };
    }

    async ensureUserManagerInitialized() {
        if (this._userManager !== undefined) {
            return;
        }

        let response = await fetch(
            ApplicationPaths.ApiAuthorizationClientConfigurationUrl
        );
        if (!response.ok) {
            throw new Error(`Could not load settings for '${ApplicationName}'`);
        }

        let settings = await response.json();
        const METADATA_OIDC = {
            issuer: `${process.env.REACT_APP_ISSUER_AUTHORITY_URL}/`,
            jwks_uri: `${process.env.REACT_APP_ISSUER_AUTHORITY_URL}/.well-known/openid-configuration`,
            authorization_endpoint: `${process.env.REACT_APP_ISSUER_AUTHORITY_URL}/connect/authorize`,
            token_endpoint: `${process.env.REACT_APP_ISSUER_AUTHORITY_URL}/connect/token`,
            userinfo_endpoint: `${process.env.REACT_APP_ISSUER_AUTHORITY_URL}/connect/userinfo`,
            end_session_endpoint: `${process.env.REACT_APP_ISSUER_AUTHORITY_URL}/connect/logout`,
            check_session_iframe: `${process.env.REACT_APP_ISSUER_AUTHORITY_URL}/connect/verify`,
            revocation_endpoint: `${process.env.REACT_APP_ISSUER_AUTHORITY_URL}/connect/revocation`,
            introspection_endpoint: `${process.env.REACT_APP_ISSUER_AUTHORITY_URL}/connect/introspect`,
        };

        settings.automaticSilentRenew = true;
        settings.includeIdTokenInSilentRenew = true;
        settings.userStore = new WebStorageStateStore({
            prefix: ApplicationName,
            store: window.sessionStorage,
        });
        settings.metadata = { ...settings.metadata, ...METADATA_OIDC };

        this._userManager = new UserManager(settings);

        this._userManager.events.addUserSignedOut(async () => {
            if (!this._userManager) return;
            await this._userManager.removeUser();
            this.updateState(null);
        });
    }

    static get instance() {
        return authService;
    }
}

const authService = new AuthorizeService();

export default authService;

export const AuthenticationResultStatus = {
    Redirect: "redirect",
    Success: "success",
    Fail: "fail",
};
