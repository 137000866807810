const isNotNullOrEmpty = (value: string | null | undefined) =>
    value !== null && value !== undefined && value.trim() !== ''
const BASE_URL = process.env.REACT_APP_API_BASE_URL
console.log('BASE_URL', BASE_URL)
export const layoutBuilderLoad = (id: string) => `${BASE_URL}/api/layoutbuilder/load/${id}`,
    layoutBuilderSave = (dmLocal: number, id: string, templateId?: string | null) =>
        `${BASE_URL}/api/layoutbuilder/save/${dmLocal}/${id}?templateId=${(typeof templateId === 'string' && templateId) || ''}`,
    layoutBuilderList = `${BASE_URL}/api/LayoutBuilder/List`,
    layoutBuilderDelete = `${BASE_URL}/api/LayoutBuilder/Delete`,
    layoutBuilderSetInUse = `${BASE_URL}/api/LayoutBuilder/SetInUse`,
    layoutBuilderExport = (id: string) => `${BASE_URL}/api/layoutbuilder/export/${id}`,
    layoutBuilderLoadTemplate = (id: string, dmLocal: number) =>
        `${BASE_URL}/api/layoutbuilder/loadtemplate/${id}/${dmLocal}`,
    menuBuilderList = `${BASE_URL}/api/MenuBuilder/List`,
    menuBuilderLoadById = (menuId: string) => `${BASE_URL}/api/MenuBuilder/LoadById/${menuId}`,
    LoadMenuByLocal = (local: string) => `${BASE_URL}/api/MenuBuilder/LoadByLocal/${local}`,
    menuBuilderChangeVisibility = (menuId: string, isVisible: boolean) =>
        `${BASE_URL}/api/MenuBuilder/ChangeVisibility/${menuId}/${isVisible ? 1 : 0}`,
    menuBuilderUpdate = (menuId: string) => `${BASE_URL}/api/MenuBuilder/Update/${menuId}`,
    tenantsList = `${BASE_URL}/api/Tenant/List`,
    tenantGetById = (tenantId: string) => `${BASE_URL}/api/Tenant/Get/${tenantId}`,
    tenantsLoad = `${BASE_URL}/api/Tenant/Load`,
    tenantSave = `${BASE_URL}/api/Tenant/Save`,
    tenantAbortCreation = `${BASE_URL}/api/Tenant/AbortCreation`,
    ufs = () => `${BASE_URL}/api/Endereco/UfSelectOptions/`,
    municipios = (cdUf: number) => `${BASE_URL}/api/Endereco/MunicipioSelectOptions/${cdUf}`
