const dataTemplateId = 'data-template-id'
const customHeadElementsClassName = 'custom-head-elements__system'
export const persistLayoutOriginInfo = data => {
    if (data['templateId'] && data['templateId'] !== '')
        document.documentElement.setAttribute(dataTemplateId, data['templateId'])
    else document.documentElement.removeAttribute(dataTemplateId)
}

export const appendHeadElements = (data, editor) => {
    const previousHeadElements = document.querySelectorAll(`.${customHeadElementsClassName}`)
    Array.prototype.forEach.call(previousHeadElements, function (node) {
        node.parentNode.removeChild(node)
    })
    if (data['headElements'] && data['headElements'] !== '') {
        const headElements = JSON.parse(data['headElements'])
        const head = editor.Canvas.getDocument().head
        for (let i = 0; i < headElements.length; i++) {
            const item = headElements[i]
            item.Attrs = item.Attrs || []
            const classIndex = item.Attrs.findIndex(x => x.Name === 'class')
            if (classIndex === -1) {
                item.Attrs.push({ Name: 'class', Value: customHeadElementsClassName })
            } else {
                item.Attrs[classIndex] = {
                    ...item.Attrs[classIndex],
                    Value: `${customHeadElementsClassName} ${item.Attrs[classIndex].Value || ''}`
                }
            }
            const htmlItem = `<${item.TagName} ${item.Attrs.filter(attr => attr.Name !== null && attr.Value !== null)
                .map(attr => `${attr.Name}="${attr.Value?.replace('"', '&quot;')}"`)
                .join(' ')} />`
            head.insertAdjacentHTML('beforeend', htmlItem)
        }
    }
}

export const getLayoutOriginInfo = () => {
    const templateId = document.documentElement.getAttribute(dataTemplateId)
    return { templateId }
}
