import { appendHeadElements, getLayoutOriginInfo, persistLayoutOriginInfo } from '../../utils/layout-builder-utils'
import { confirmModalOpenEvtName, notificationCenterNotifyEvtName } from 'constants/events-names'

import Backbone from 'backbone'
import { SecureHttpClient } from 'services/http-client-service'
import { layoutBuilderLoadTemplate } from 'constants/api-routes'
import { parseTemplate } from './export-generate-template'
import { template } from 'lodash'
import { trigger } from 'helpers/events'

const dataTemplateId = 'data-template-id'

function fragmentFromString(strHTML) {
    var temp = document.createElement('template')
    temp.innerHTML = strHTML
    return temp.content
}

const SelectStarterTemplate = (editor, initConfig) => {
    const pfx = editor.getConfig('stylePrefix')
    const modal = editor.Modal
    const codeViewer = editor.CodeManager.getViewer('CodeMirror').clone()
    let viewerEditor = codeViewer.editor
    const $ = Backbone.$
    // Init buttons

    const btnNotUseTemplate = document.createElement('button')
    btnNotUseTemplate.type = 'button'
    btnNotUseTemplate.innerHTML = 'Não, Obrigado'
    btnNotUseTemplate.className = `m-2 btn btn-secondary`

    return {
        run(editor, sender, opts = {}) {
            console.warn(` you are in my custom SELECT STARTER TEMPLATE command`)
            sender && sender.set && sender.set('active', 0)
            const { dmLocal } = editor.getConfig('storageManager')
            const config = editor.getConfig()
            const modal = editor.Modal
            const pfx = config.stylePrefix
            this.cm = editor.CodeManager || null

            const itemTemplate = `
      <style>
        section{
            padding: 100px 0;
        }
        .details-card {
          background: #ecf0f1;
        }
        
        .card-content {
          background: #ffffff;
          border: 4px;
          box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
        }
        
        .card-img {
          position: relative;
          overflow: hidden;
          border-radius: 0;
          z-index: 1;
        }
        
        .card-img img {
          width: 100%;
          height: auto;
          display: block;
        }
        
        .card-img span {
          position: absolute;
            top: 15%;
            left: 12%;
            background: #1ABC9C;
            padding: 6px;
            color: #fff;
            font-size: 12px;
            border-radius: 4px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            -ms-border-radius: 4px;
            -o-border-radius: 4px;
            transform: translate(-50%,-50%);
        }
        .card-img span h4{
                font-size: 12px;
                margin:0;
                padding:10px 5px;
                line-height: 0;
        }
        .card-desc {
          padding: 1.25rem;
        }
        
        .card-desc h3 {
          color: #000000;
            font-weight: 600;
            font-size: 1.5em;
            line-height: 1.3em;
            margin-top: 0;
            margin-bottom: 5px;
            padding: 0;
        }
        
        .card-desc p {
          color: #747373;
            font-size: 14px;
          font-weight: 400;
          font-size: 1em;
          line-height: 1.5;
          margin: 0px;
          margin-bottom: 20px;
          padding: 0;
          font-family: 'Raleway', sans-serif;
        }
        .btn-card{
          background-color: #1ABC9C;
          color: #fff;
          box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
            padding: .84rem 2.14rem;
            font-size: .81rem;
            -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
            transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
            -o-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
            margin: 0;
            border: 0;
            -webkit-border-radius: .125rem;
            border-radius: .125rem;
            cursor: pointer;
            text-transform: uppercase;
            white-space: normal;
            word-wrap: break-word;
            color: #fff;
        }
        .btn-card:hover {
            background: orange;
        }
        a.btn-card {
            text-decoration: none;
            color: #fff;
        }
      </style>
      <section class="details-card">
      <div class="container">
          <div class="row">
              <div class="col-md-4">
                  <div class="card-content">
                      <div class="card-img">
                          <img src="https://source.unsplash.com/iflRMZelx0M/380x230" alt="">
                          <span><h4>moderno</h4></span>
                      </div>
                      <div class="card-desc">
                          <h3>Layout 1</h3>
                          <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laboriosam, voluptatum! Dolor quo, perspiciatis
                              voluptas totam</p>
                              <button data-template-id="1" class="btn-card select-template">Selecionar</button>   
                      </div>
                  </div>
              </div>
              <div class="col-md-4">
                  <div class="card-content">
                      <div class="card-img">
                          <img src="https://placeimg.com/380/230/animals" alt="">
                          <span><h4>clássico</h4></span>
                      </div>
                      <div class="card-desc">
                          <h3>Layout 2</h3>
                          <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laboriosam, voluptatum! Dolor quo, perspiciatis
                              voluptas totam</p>
                              <button data-template-id="2" class="btn-card select-template">Selecionar</button>   
                      </div>
                  </div>
              </div>
              <div class="col-md-4">
                  <div class="card-content">
                      <div class="card-img">
                          <img src="https://placeimg.com/380/230/tech" alt="">
                          <span><h4>minimalista</h4></span>
                      </div>
                      <div class="card-desc">
                          <h3>Layout 3</h3>
                          <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laboriosam, voluptatum! Dolor quo, perspiciatis
                              voluptas totam</p>
                              <button data-template-id="3" class="btn-card select-template">Selecionar</button>   
                      </div>
                  </div>
              </div>
          </div>
          <div class="row" style="margin-top:20px;">
              <div class="col-md-4">
                  <div class="card-content">
                      <div class="card-img">
                          <img src="https://source.unsplash.com/iflRMZelx0M/380x230" alt="">
                          <span><h4>moderno</h4></span>
                      </div>
                      <div class="card-desc">
                          <h3>Layout 4</h3>
                          <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laboriosam, voluptatum! Dolor quo, perspiciatis
                              voluptas totam</p>
                              <button data-template-id="4" class="btn-card select-template">Selecionar</button>   
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>`

            const oHtmlEd = this.buildEditor('javascript', 'hopscotch', 'JSX')
            const oCsslEd = this.buildEditor('css', 'hopscotch', 'CSS')
            this.htmlEditor = oHtmlEd.el
            this.cssEditor = oCsslEd.el
            const $editors = $(fragmentFromString(itemTemplate))
            $editors.find('.select-template').on('click', function (e) {
                const templateId = this.getAttribute('data-template-id')

                trigger(confirmModalOpenEvtName, {
                    title: 'Atenção',
                    message: `Confirma o carregamento do layout selecionado ? Esta ação irá limpar totalmente o conteúdo atual em edição.`,
                    executor: okCancel =>
                        new Promise((resolve, reject) => {
                            if (okCancel === 'ok') {
                                SecureHttpClient.Get(layoutBuilderLoadTemplate(templateId, dmLocal))
                                    .then(json => {
                                        // console.log("json", json);
                                        editor.setComponents(json.htmlLayout)
                                        editor.setStyle(json.styles || '')
                                        try {
                                            persistLayoutOriginInfo(json)
                                        } catch (e) {
                                            console.log('DEU RUIM 1', e)
                                        }

                                        try {
                                            appendHeadElements(json, editor)
                                        } catch (e) {
                                            console.log('DEU RUIM 2', e)
                                        }
                                    })
                                    .finally(() => {
                                        resolve()
                                        modal.close()
                                        trigger(notificationCenterNotifyEvtName, {
                                            message: 'Layout carregado com sucesso!',
                                            type: 'success'
                                        })
                                    })
                                    .catch(() => {
                                        trigger(notificationCenterNotifyEvtName, {
                                            message: 'Falha ao carregar o Layout!',
                                            type: 'error'
                                        })
                                    })
                            } else {
                                resolve()
                            }
                        })
                })
            })
            // const $editors = $(`<div class="${pfx}export-dl"></div>`);
            // $editors
            //   .append(oHtmlEd.$el)
            //   .append(oCsslEd.$el)
            //   .append(btnNotUseTemplate)
            //   .append(btnSelectTemplate);

            $editors.append(btnNotUseTemplate)
            this.$editors = $editors

            btnNotUseTemplate.onclick = () => {
                const modal = editor.Modal
                modal && modal.close()
            }

            modal
                .open({
                    title: 'Selecione um layout para começar',
                    content: this.$editors
                })
                .getModel()
                .once('change:open', () => editor.stopCommand(this.id))

            // const layoutJSX = parseTemplate(editor, opts);
            // this.htmlEditor.setContent(layoutJSX);
            // this.cssEditor.setContent(editor.getCss());
        },

        stop(editor) {
            const modal = editor.Modal
            modal && modal.close()
        },

        buildEditor(codeName, theme, label) {
            const input = document.createElement('textarea')
            !this.codeMirror && (this.codeMirror = this.cm.getViewer('CodeMirror'))

            const el = this.codeMirror.clone().set({
                label,
                codeName,
                theme,
                input
            })

            const $el = new this.cm.EditorView({
                model: el,
                config: this.cm.getConfig()
            }).render().$el

            el.init(input)

            return { el, $el }
        }
    }
}

export default SelectStarterTemplate
