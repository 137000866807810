import * as Yup from 'yup'

import { Button, Col, Container, FormGroup, Row } from 'reactstrap'
import { ButtonGroup, Dropdown } from 'react-bootstrap'
import { DmStatusTenantCreationEnum, DmTipoClienteEnum } from 'actcon-portal-types'
import {
    EvolutionProgressStepStatusType,
    EvolutionProgressStepType
} from 'types/components/common/evolution-progress-bar'
import { Input, InputMask } from 'components/form'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { municipios, tenantAbortCreation, tenantSave, ufs } from 'constants/api-routes'
import {
    tenantAbortCreationSuccessfully,
    tenantInsertUpdateFail,
    tenantInsertUpdateSuccessfully
} from 'constants/messages'

import EvolutionProgressBar from 'components/evolution-progress-bar'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import { ITenantCreationProgress } from 'types/abstractions/i-tenant-creation-progress'
import { Plus } from 'react-feather'
import { SecureHttpClient } from 'services/http-client-service'
import Select from 'components/form/select'
import { SelectOptionModel } from 'generated/portal-graphql-types'
import TenantCreationContext from 'contexts/tenant-creation-context'
import TenantCreationStepsAndTasks from 'constants/tenant-creation-steps-tasks'
import { TenantModel } from '@/types/tenant/tenant-model-type'
import { parseUrlTemplate } from 'utils/strings-utils'
import { schema } from './form-schema'
import tenantCreationHub from 'classes/signalr/tenant-creation-signalr-connection'
import { tenantInsertUpdateRoute } from 'constants/app-routes'
import tenantService from 'services/tenant-service'
import useCurrentTenant from '../../../hooks/use-current-tenant'
import { useHistory } from 'react-router-dom'

const updateTaskStatus = (
    progressModel: ITenantCreationProgress,
    step: EvolutionProgressStepType,
    isInsert: boolean
): EvolutionProgressStepType => {
    const nextTaskId = TenantCreationStepsAndTasks.GetNextTaskId(
        TenantCreationStepsAndTasks.TenantCreationStep1,
        progressModel.Step1TasksCompleted || [],
        progressModel.IsSubSite,
        isInsert
    )
    console.log('progressModel', progressModel)
    console.log('nextTaskId', nextTaskId)
    switch (step.id) {
        case TenantCreationStepsAndTasks.FillBasicInfo:
            return { ...step, status: 'done' }
        case TenantCreationStepsAndTasks.CreateDatabaseTaskId:
        case TenantCreationStepsAndTasks.CreateUserDatabaseTaskId:
        case TenantCreationStepsAndTasks.DataSeedTaskId:
        case TenantCreationStepsAndTasks.RequestRegisterDomainTaskId:
        case TenantCreationStepsAndTasks.CheckRegisterDomainStatusTaskId:
        case TenantCreationStepsAndTasks.RequestRegisterOAuthSettingsTaskId:
        case TenantCreationStepsAndTasks.CheckRegisterOAuthSettingsStatusTaskId:
            return {
                ...step,
                status: progressModel.Step1TasksCompleted?.includes(step.id)
                    ? 'done'
                    : progressModel.DmCreationStatus === DmStatusTenantCreationEnum.Running && nextTaskId === step.id
                      ? 'running'
                      : progressModel.DmCreationStatus === DmStatusTenantCreationEnum.Error && nextTaskId === step.id
                        ? 'error'
                        : progressModel.DmCreationStatus === DmStatusTenantCreationEnum.Aborted &&
                            nextTaskId === step.id
                          ? 'aborted'
                          : 'pending'
            }
        default:
            return step
    }
}

const allCreationSteps: EvolutionProgressStepType[] = [
    {
        status: 'pending',
        id: TenantCreationStepsAndTasks.FillBasicInfo,
        title: 'Preenchimento informações básicas'
    },
    {
        status: 'pending',
        id: TenantCreationStepsAndTasks.CreateDatabaseTaskId,
        title: 'Criando banco de dados'
    },
    {
        status: 'pending',
        id: TenantCreationStepsAndTasks.CreateUserDatabaseTaskId,
        title: 'Criando usuário do banco de dados'
    },
    {
        status: 'pending',
        id: TenantCreationStepsAndTasks.DataSeedTaskId,
        title: 'Populando base de dados'
    },
    {
        status: 'pending',
        id: TenantCreationStepsAndTasks.RequestRegisterDomainTaskId,
        title: 'Registrando domínio'
    },
    {
        status: 'pending',
        id: TenantCreationStepsAndTasks.CheckRegisterDomainStatusTaskId,
        title: 'Checando registro do domínio'
    },
    {
        status: 'pending',
        id: TenantCreationStepsAndTasks.RequestRegisterOAuthSettingsTaskId,
        title: 'Criando config. OAuth'
    },
    {
        status: 'pending',
        id: TenantCreationStepsAndTasks.CheckRegisterOAuthSettingsStatusTaskId,
        title: 'Checando a config. OAuth'
    }
]

const TenantCreationStep1 = () => {
    const step = 1

    const formRef = useRef<FormHandles>(null)
    const {
        data: tenantModel,
        isInsert,
        isSubSite,
        setIsLoading,
        setTenantModel,
        setIsShowingMessage,
        setMessageParams,
        creationProgress,
        updateCreationProgress
    } = useContext(TenantCreationContext)
    const tenantServiceInstance = tenantService()
    const { current: currentTenant } = useCurrentTenant()
    const [hostNames, setHostNames] = useState<string[] | null>([])
    const [corsAllowedOrigins, setCorsAllowedOrigins] = useState<string[] | null>([])

    const history = useHistory()

    const addItemHostNames = () => {
        formRef.current?.setErrors({})
        setHostNames((prev: string[] | null) => (prev ? [...prev, ''] : ['']))
    }

    const removeItemHostNames = (e: any, key: number) => {
        e.preventDefault()
        formRef.current?.setErrors({})
        var filtered = (hostNames && hostNames.filter((item: string, index: number) => key !== index)) || []

        console.log('filtered', filtered)
        console.log('key', key)

        var currentData = formRef.current?.getData()
        var newData = { ...currentData, HostNames: filtered }
        console.log('newData', newData)
        formRef.current?.setData(newData)

        console.log('formRef.current', formRef.current)
        setHostNames(filtered)
    }

    const addItemCorsAllowedOrigins = () => {
        formRef.current?.setErrors({})
        setCorsAllowedOrigins((prev: string[] | null) => (prev ? [...prev, ''] : ['']))
    }

    const removeItemCorsAllowedOrigins = (e: any, key: any) => {
        e.preventDefault()
        setCorsAllowedOrigins(
            (prev: Array<string> | null) => (prev && prev.filter((item: string, index: number) => key !== index)) || []
        )
        var currentData = formRef.current?.getData()

        formRef.current?.setErrors({})
        formRef.current?.setData({
            ...currentData,
            CorsAllowedOrigins: corsAllowedOrigins
        })
    }

    const stepStatusFormatter = (status: EvolutionProgressStepStatusType) =>
        status === 'done' ? 'Concluído' : status === 'error' ? 'Falha' : 'Pendente'

    const getStatus = (status: EvolutionProgressStepStatusType, taskId: number) => {
        switch (taskId) {
            case TenantCreationStepsAndTasks.FillBasicInfo:
                break
            case TenantCreationStepsAndTasks.CreateDatabaseTaskId:
                break
            default:
                return ''
        }
    }
    const isInit = useRef(false)
    const [isDmPorteFixed, setIsDmPorteFixed] = useState(false)
    const hubsInitializing = useRef(false)
    const [isUrlBaseFixed, setIsUrlBaseFixed] = useState(false)
    const [ufSelectOptions, setUfSelectOptions] = useState<SelectOptionModel[]>([])
    const [municipioSelectOptions, setMunicipioSelectOptions] = useState<SelectOptionModel[]>([])
    const [creationSteps, setCreationSteps] = useState<EvolutionProgressStepType[]>([])

    // const handleOnCreationProgressChanged = useCallback(
    //     (e: CreationProgressModel) => {
    //         console.log('CHEGUEI AQUI => handleOnCreationProgressChanged ', { notification: e, when: new Date() })
    //         if (!tenantModel) return

    //         setTenantModel(prev => ({
    //             ...(prev || {}),
    //             DmCreationStatus: e.DmCreationStatus,
    //             StepsCompleted: e.StepsCompleted,
    //             Step1TasksCompleted: e.Step1TasksCompleted
    //         }))

    //         setCreationSteps(
    //             e.IsSubSite
    //                 ? allCreationSteps
    //                       .filter(x => TenantCreationStepsAndTasks.TasksOfStep1SubTenant.includes(x.id))
    //                       .map(x => updateTaskStatus(e, x, false))
    //                 : allCreationSteps
    //                       .filter(x => TenantCreationStepsAndTasks.TasksOfStep1.includes(x.id))
    //                       .map(x => updateTaskStatus(e, x, false))
    //         )
    //     },
    //     [allCreationSteps, setTenantModel, tenantModel]
    // )

    useEffect(() => {
        console.log('cheguei aqui no efffect do Hubs.')
        if (
            isInsert ||
            !currentTenant ||
            creationProgress.StepsCompleted.includes(TenantCreationStepsAndTasks.TenantCreationStep1)
        ) {
            return
        }
        console.log('cheguei aqui no efffect do Hubs.')
        //hubsInitializing.current = true
        const hubsGroupId = `tenantCreation:${currentTenant.id}:step_${step}`
        // SelectOptionListAsync({ query: UF_SELECT_OPTS }).then((r) => {
        //   setUfSelectOptions(r?.data?.selectOption || []);
        // });
        const hub = tenantCreationHub()
        hub.OnConnected()
            .then(() => {
                console.warn('HUBS CONNECTED! JOIN TO GROUP: ' + hubsGroupId)
                hub.JoinToGroup(hubsGroupId)
                    .then(() => {
                        console.warn('JOINED TO GROUP: ' + hubsGroupId)
                        hub.OnCreationProgressChanged((...args) =>
                            console.log('OnCreationProgressChanged is Raised', { arguments: args })
                        )
                        hub.OnCreationProgressChanged(e => {
                            console.warn('ONCREATIONPROGRESSCHANGED FIRED! ')
                            updateCreationProgress({
                                DmCreationStatus: e.DmCreationStatus,
                                StepsCompleted: e.StepsCompleted || [],
                                Step1TasksCompleted: e.Step1TasksCompleted || [],
                                IsFromHubs: true
                            })

                            setCreationSteps(
                                e.IsSubSite
                                    ? allCreationSteps
                                          .filter(x => TenantCreationStepsAndTasks.TasksOfStep1SubTenant.includes(x.id))
                                          .map(x => updateTaskStatus(e, x, false))
                                    : allCreationSteps
                                          .filter(x => TenantCreationStepsAndTasks.TasksOfStep1.includes(x.id))
                                          .map(x => updateTaskStatus(e, x, false))
                            )
                        })
                    })
                    .catch(e => console.warn(`HUBS GROUP(${hubsGroupId}) CONNECTION ATTEMPT FAILED :(`, e))
                //.finally(() => (hubsInitializing.current = false))
            })
            .catch(e => console.warn('HUBS CONNECTION FAILED :(', e))
        //.finally(() => (hubsInitializing.current = false))

        return (function (x) {
            return () => {
                console.warn('HUBS DISCONNECTED! UNJOIN FROM GROUP: ' + x)
                hubsInitializing.current = false
                hub.UnJoinFromGroup(x)
            }
        })(hubsGroupId)
    }, [creationProgress.StepsCompleted, currentTenant, isInsert, updateCreationProgress])

    const loadUfAsync = () =>
        SecureHttpClient.Get<SelectOptionModel[]>(ufs()).then(r => {
            setUfSelectOptions(r || [])
        })

    useEffect(() => {
        console.log('[EFFECT PROBLEMÁTICO]:ENTREI ', { tenantModel })
        if (
            !tenantModel ||
            //isInit.current ||
            //(isInit.current === true && creationProgress.DmCreationStatus === DmStatusTenantCreationEnum.Running) ||
            creationProgress.IsFromHubs
        ) {
            console.log('[EFFECT PROBLEMÁTICO]:VOU SAIR SAIR')
            return
        }
        isInit.current = true
        console.log('[EFFECT PROBLEMÁTICO]:NÃO SAI')
        // formRef.current?.setErrors({})
        if (isInsert) {
            console.log('[EFFECT PROBLEMÁTICO]: IS INSERT')
            //setIsLoading(true)
            loadUfAsync()
                .then(() => {
                    console.log('[EFFECT PROBLEMÁTICO]: UF LOADED, SETTING FORM STATE')
                    formRef.current?.setData(tenantModel || {})
                    console.log('[EFFECT PROBLEMÁTICO]: UPDATING CREATION STEPS')
                    setCreationSteps(
                        isSubSite
                            ? allCreationSteps.filter(x =>
                                  TenantCreationStepsAndTasks.TasksOfStep1SubTenant.includes(x.id)
                              )
                            : allCreationSteps.filter(x => TenantCreationStepsAndTasks.TasksOfStep1.includes(x.id))
                    )
                })
                .finally(() => {
                    console.log('[EFFECT PROBLEMÁTICO]: FINALLY, ')
                    //setIsLoading(false)
                })
        } else if (tenantModel.TenantId) {
            console.log('[EFFECT PROBLEMÁTICO]: TEM TENANT ID')
            //setIsLoading(true)
            loadUfAsync()
                .then(() => {
                    console.log('[EFFECT PROBLEMÁTICO]: UF LOADED, GETTING MUNICIPIOS')
                    SecureHttpClient.Get<SelectOptionModel[]>(municipios(tenantModel.CdUf))
                        .then(r => {
                            setMunicipioSelectOptions(r || [])
                            console.log('[EFFECT PROBLEMÁTICO]: UF LOADED, MUNICIPIOS LOADED')
                        })
                        .finally(() => {
                            console.log('[EFFECT PROBLEMÁTICO]: FINALLY UPDATING CREATION STEPS')
                            const updatedCreationSteps = isSubSite
                                ? allCreationSteps
                                      .filter(x => TenantCreationStepsAndTasks.TasksOfStep1SubTenant.includes(x.id))
                                      .map(x => updateTaskStatus(tenantModel, x, false))
                                : allCreationSteps
                                      .filter(x => TenantCreationStepsAndTasks.TasksOfStep1.includes(x.id))
                                      .map(x => updateTaskStatus(tenantModel, x, false))
                            console.log(
                                '[EFFECT PROBLEMÁTICO]: LETS GOING UPDATE THE PROGRESS ....',
                                updatedCreationSteps
                            )
                            setCreationSteps(updatedCreationSteps)
                            console.log('[EFFECT PROBLEMÁTICO]: SETTING CORS ALLOWED ORGING + HOSTNAMES')
                            setCorsAllowedOrigins(tenantModel.CorsAllowedOrigins || [])
                            setHostNames(tenantModel.HostNames || [])
                            console.log('[EFFECT PROBLEMÁTICO]: SETTING THE FORM STATE')
                            setTimeout(
                                () =>
                                    formRef.current?.setData({
                                        ...tenantModel,
                                        CdMunicipio: `${tenantModel.CdMunicipio}`
                                    }),
                                0
                            )
                        })
                })
                .finally(() => {
                    //setIsLoading(false)
                })
        } else {
            console.log('[EFFECT PROBLEMÁTICO]: NÃO TEM TENANT ID')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantModel])

    const handleSubmit = async (data: any, { reset }: { reset: () => void }) => {
        try {
            console.log('formData', formRef.current?.getData())
            console.log('tenantModel', tenantModel)
            console.log('hostNames', hostNames)
            console.log('corsAllowedOrigins', corsAllowedOrigins)
            console.log('TenantId FIELD VALUE', formRef.current?.getFieldValue('TenantId'))

            await schema.validate(data, { abortEarly: false })
            //setIsLoading(true)
            SecureHttpClient.Post(tenantSave, data)
                .then(response => {
                    if (response.ok) {
                        return response.json() // as Promise<{ model: TenantModel; isInsert: boolean }>
                    }
                    throw new Error('Something went wrong')
                })
                .then(({ model, isInsert }) => {
                    setMessageParams({
                        message: tenantInsertUpdateSuccessfully,
                        buttonCallback: () =>
                            new Promise<void>(resolve => {
                                setIsShowingMessage(false)
                                if (isInsert) {
                                    history.push(
                                        parseUrlTemplate(tenantInsertUpdateRoute, {
                                            action: 'edit'
                                        })
                                    )
                                }
                                resolve() // closes the modal message.
                                if (!isInsert) {
                                    setTenantModel(prev => ({ ...prev, ...(model || {}) }))
                                    updateCreationProgress(prev => ({
                                        ...(prev || {}),
                                        DmCreationStatus: model.DmCreationStatus,
                                        StepsCompleted: model.StepsCompleted || [],
                                        Step1TasksCompleted: model.Step1TasksCompleted || [],
                                        IsFromHubs: false
                                    }))
                                }

                                tenantServiceInstance.Set(tenantServiceInstance.MapFromServerToLocal(model))
                            })
                    })
                    setIsShowingMessage(true)
                })
                .catch(err => {
                    setMessageParams({
                        message: tenantInsertUpdateFail + ` reason: ${err}`,
                        buttonCallback: () =>
                            new Promise<void>((resolve, reject) => {
                                setIsShowingMessage(false)
                                resolve()
                            })
                    })
                    setIsShowingMessage(true)
                })
                .finally(() => {
                    console.log('TenantId FIELD VALUE', formRef.current?.getFieldValue('TenantId'))
                })
            formRef.current?.setErrors({})
            //reset();
        } catch (err) {
            console.log('DEU RUIM', err)
            if (err instanceof Yup.ValidationError) {
                //console.log("Ooops... schema invalid", err);
                const errorMessages = (err as Yup.ValidationError).inner.reduce(
                    (acc, error) => ({
                        ...acc,
                        ...{ [error.path as string]: error.message }
                    }),
                    {}
                )
                console.log('DEU RUIM', errorMessages)
                formRef.current?.setErrors(errorMessages)
            }
        }
    }
    // const [masked, setMasked] = useState({})
    // const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     var value = event.target.value
    //     var newState = {
    //         mask: '',
    //         value: value.replaceAll(/[^a-z0-9\-_]/gi, '')
    //     }
    //     newState.mask = new Array(newState.value.length + 1).fill('*').join('')
    //     setMasked(newState)
    // }

    const handleSaveDraft = () => {
        if (formRef.current) {
            formRef.current.setFieldValue('IsDraft', true)
            formRef.current.submitForm()
        }
        console.log('vamos salvar o rascunho')
    }

    const handleSubmitCreation = () => {
        if (formRef.current) {
            formRef.current.setFieldValue('IsDraft', false)
            formRef.current.submitForm()
        }
        console.log('vamos iniciar a  criação')
    }

    const handleAbortCreation = () => {
        setIsLoading(true)
        SecureHttpClient.Post(tenantAbortCreation)
            .then(async response => {
                if (response.ok) {
                    setMessageParams({
                        message: tenantAbortCreationSuccessfully,
                        buttonCallback: () =>
                            new Promise<void>(resolve => {
                                setIsShowingMessage(false)
                                window.location.reload()
                                resolve() // closes the modal message.
                            })
                    })
                    setIsShowingMessage(true)
                } else {
                    setMessageParams({
                        message: tenantInsertUpdateFail + ` \n\nreason: ${await response.text()}`,
                        buttonCallback: () =>
                            new Promise<void>((resolve, reject) => {
                                setIsShowingMessage(false)
                                resolve()
                            })
                    })
                    setIsShowingMessage(true)
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const slugify = (text: string) =>
        text
            .toString()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .trim()
            .replace(/\s+/g, '-')
            .replace(/[^\w-]+/g, '')
            .replace(/--+/g, '-')

    const computeProposalUrl = () => {
        const dmTipoCliente = parseInt(formRef.current?.getFieldValue('DmTipoCliente'), 10)

        var clientId = formRef.current?.getFieldValue('ClientId')
        const cdUf = formRef.current?.getFieldValue('CdUf')
        const cdMunicipio = formRef.current?.getFieldValue('CdMunicipio')
        const municipio = municipioSelectOptions.find(x => x.value === cdMunicipio)

        const uf = ufSelectOptions.find(x => x.value === cdUf)
        const prefix =
            dmTipoCliente === DmTipoClienteEnum.Camara
                ? 'cam'
                : dmTipoCliente === DmTipoClienteEnum.Prefeitura
                  ? ''
                  : slugify(clientId)

        const baseUrl = '.portalfacil.srv.br'

        const computedAdminUrl =
            'https://' +
            [(uf?.ExtendedProps?.Value1 || '').toLowerCase(), slugify(municipio?.label || ''), prefix, 'portaladmin']
                .filter(x => x.trim() !== '')
                .join('-') +
            baseUrl

        const computedPublicUrl =
            'https://' +
            [(uf?.ExtendedProps?.Value1 || '').toLowerCase(), slugify(municipio?.label || ''), prefix, 'portalpublico']
                .filter(x => x.trim() !== '')
                .join('-') +
            baseUrl

        formRef.current?.setFieldValue('UriBaseAdmin', computedAdminUrl)
        formRef.current?.setFieldValue('UriBasePublic', computedPublicUrl)
    }

    const handleTipoClientChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const tipoCliente = parseInt(e.target.value, 10)
        switch (tipoCliente) {
            case DmTipoClienteEnum.Camara:
            case DmTipoClienteEnum.Prefeitura:
                setIsDmPorteFixed(true)
                setIsUrlBaseFixed(true)
                break
            default:
                setIsDmPorteFixed(false)
                setIsUrlBaseFixed(false)
        }
        const cdMunicipio = formRef.current?.getFieldValue('CdMunicipio')
        const selectedMunicipio = municipioSelectOptions.find(x => x.value === cdMunicipio)
        if (selectedMunicipio?.ExtendedProps?.Value1) {
            formRef.current?.setFieldValue('DmPorteMunicipio', `${selectedMunicipio?.ExtendedProps?.Value1}`)
        }

        computeProposalUrl()
    }

    const handleUfChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const cdUf = parseInt(e.target.value, 10)
        if (cdUf > 0) {
            formRef.current?.setFieldValue('CdMunicipio', '')
            formRef.current?.setFieldValue('DmPorteMunicipio', '-1')
            SecureHttpClient.Get<SelectOptionModel[]>(municipios(cdUf)).then(r => {
                setMunicipioSelectOptions(r || [])
            })

            // SelectOptionListAsync({
            //   query: MUNICIPIO_SELECT_OPTS,
            //   variables: { cdUf },
            // }).then((r) => {
            //   setMunicipioSelectOptions(r?.data?.selectOption || []);
            // });
        }
        computeProposalUrl()
    }

    const handleMunicipioChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedMunicipio = municipioSelectOptions.find(x => x.value === e.target.value)
        if (selectedMunicipio?.ExtendedProps?.Value1) {
            formRef.current?.setFieldValue('DmPorteMunicipio', `${selectedMunicipio?.ExtendedProps?.Value1}`)
        }
        computeProposalUrl()
    }

    return (
        <>
            <Container>
                {creationSteps && (
                    <EvolutionProgressBar
                        steps={creationSteps}
                        title="Progresso de criação"
                        subtitle="Acompanhe abaixo o progresso de criação do novo cliente."
                    />
                )}
                <hr className="mb-4" />

                <Form ref={formRef} onSubmit={handleSubmit}>
                    <Input type="hidden" name="IsDraft" />
                    <Input type="hidden" name="TenantId" />
                    <Input type="text" name="IsSubSite" style={{ display: 'none' }} />
                    <Input type="text" name="ParentTenantId" style={{ display: 'none' }} />
                    <fieldset id="fds_dados_pesquisa">
                        <div className="row">
                            <div className="col-md-4">
                                <InputMask
                                    label="ID do Cliente"
                                    readOnly={!isInsert}
                                    // formatChars={{
                                    //     '9': '[0-9]',
                                    //     a: '[A-Za-z]',
                                    //     '*': '[A-Za-z0-9-_]'
                                    // }}
                                    name="ClientId"
                                    // alwaysShowMask={true}
                                    // maskChar=""
                                    mask={new Array(100).fill('*').join('')}
                                    placeholder="MeuNovoCliente"
                                    disabled={
                                        !isInsert ||
                                        tenantModel?.DmCreationStatus === DmStatusTenantCreationEnum.Running
                                    }
                                />
                            </div>
                            <div className="col-md-8">
                                <Input
                                    type="text"
                                    label="Nome"
                                    name="Name"
                                    disabled={tenantModel?.DmCreationStatus === DmStatusTenantCreationEnum.Running}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <Input
                                    label="Tipo do Cliente"
                                    readOnly={!isInsert}
                                    type="select"
                                    name="DmTipoCliente"
                                    onChange={handleTipoClientChanged}
                                    dataOptions={[
                                        {
                                            label: 'Prefeitura',
                                            value: '1'
                                        },
                                        {
                                            label: 'Câmara',
                                            value: '2'
                                        },
                                        {
                                            label: 'Outro',
                                            value: '3'
                                        }
                                    ]}
                                    disabled={tenantModel?.DmCreationStatus === DmStatusTenantCreationEnum.Running}
                                />
                            </div>
                            <div className="col-md-6">
                                <Input
                                    label="Estado"
                                    readOnly={!isInsert}
                                    type="select"
                                    name="CdUf"
                                    dataOptions={ufSelectOptions}
                                    onChange={handleUfChanged}
                                    disabled={tenantModel?.DmCreationStatus === DmStatusTenantCreationEnum.Running}
                                />
                            </div>
                            <div className="col-md-6">
                                <Input
                                    label="Municipio"
                                    readOnly={!isInsert}
                                    type="select"
                                    name="CdMunicipio"
                                    dataOptions={municipioSelectOptions}
                                    onChange={handleMunicipioChanged}
                                    //disabled={tenantModel?.DmCreationStatus === DmStatusTenantCreationEnum.Running}
                                />
                            </div>
                            <div className="col-md-6">
                                <Input
                                    label="Porte"
                                    readOnly={!isInsert || isDmPorteFixed}
                                    type="select"
                                    name="DmPorteMunicipio"
                                    dataOptions={[
                                        {
                                            label: 'Pequeno',
                                            value: '1'
                                        },
                                        {
                                            label: 'Médio',
                                            value: '2'
                                        },
                                        {
                                            label: 'Grande',
                                            value: '3'
                                        }
                                    ]}
                                    disabled={tenantModel?.DmCreationStatus === DmStatusTenantCreationEnum.Running}
                                />
                            </div>
                        </div>
                        <div>
                            <Input
                                type="text"
                                name="NamePersonInCharge"
                                label="Nome do responsável"
                                disabled={tenantModel?.DmCreationStatus === DmStatusTenantCreationEnum.Running}
                            />
                        </div>
                        <div className="row">
                            <div className="col-md-8">
                                <Input
                                    type="text"
                                    name="TelephoneNumber"
                                    label="Número de telefone"
                                    disabled={tenantModel?.DmCreationStatus === DmStatusTenantCreationEnum.Running}
                                />
                            </div>
                            <div className="col-md-4">
                                <Input
                                    type="text"
                                    name="TelephoneExtensionNumber"
                                    label="Número do ramal"
                                    disabled={tenantModel?.DmCreationStatus === DmStatusTenantCreationEnum.Running}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <Input
                                    label="Motor do banco de dados"
                                    readOnly={!isInsert || isSubSite}
                                    type="select"
                                    name="DatabaseEngine"
                                    dataOptions={[
                                        {
                                            label: 'MS SQL Server',
                                            value: 'MSSQL'
                                        },
                                        {
                                            label: 'MySQL',
                                            value: 'MYSQL'
                                        }
                                    ]}
                                    disabled={tenantModel?.DmCreationStatus === DmStatusTenantCreationEnum.Running}
                                />
                            </div>
                            <div className="col-md-6">
                                <Input
                                    type="number"
                                    name="ImageQuality"
                                    min={1}
                                    max={100}
                                    label="Qualidade da Imagem"
                                    disabled={tenantModel?.DmCreationStatus === DmStatusTenantCreationEnum.Running}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <Input
                                    label="Sub-site de:"
                                    readOnly={true}
                                    type="text"
                                    name="ParentName"
                                    placeholder="teste"
                                    disabled={tenantModel?.DmCreationStatus === DmStatusTenantCreationEnum.Running}
                                />
                            </div>
                            <div className="col-md-6">
                                <Select
                                    label="Tipo de cadastro de Internauta:"
                                    name="UserRegisteringType"
                                    readOnly={!isInsert}
                                    options={[
                                        {
                                            name: 'Compartilhado (Comum a todos os sites)',
                                            value: '1'
                                        },
                                        {
                                            name: 'Exclusivo ao site em questão',
                                            value: '2'
                                        }
                                    ]}
                                    disabled={tenantModel?.DmCreationStatus === DmStatusTenantCreationEnum.Running}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <Select
                                    label="Solicitar login para abrir o portal (intranet)?"
                                    name="IsAuthenticatedEnvironment"
                                    readOnly={!isInsert}
                                    options={[
                                        {
                                            name: 'Não (somente nas rotinas que o internauta é relacionado)',
                                            value: '0'
                                        },
                                        {
                                            name: 'Sim (solicita login para qualquer acesso ao site público do portal)',
                                            value: '1'
                                        }
                                    ]}
                                    disabled={tenantModel?.DmCreationStatus === DmStatusTenantCreationEnum.Running}
                                />
                            </div>
                            <div className="col-md-6">
                                <Select
                                    label="Tipo de Login:"
                                    name="LoginType"
                                    defaultValue="-1"
                                    readOnly={!isInsert}
                                    options={[
                                        {
                                            name: 'Padrão (utilizar o login do Unific)',
                                            value: '0'
                                        },
                                        {
                                            name: 'Custom (utilizar um login específico)',
                                            value: '1'
                                        }
                                    ]}
                                    disabled={tenantModel?.DmCreationStatus === DmStatusTenantCreationEnum.Running}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <Input
                                    label="URL Admin"
                                    prefix="https://"
                                    sufix=".portalfacil.srv.br"
                                    usePrefixAndSufixAsValue
                                    readOnly={!isInsert || isUrlBaseFixed}
                                    type="text"
                                    name="UriBaseAdmin"
                                    placeholder="uf-municipio-admin"
                                    disabled={tenantModel?.DmCreationStatus === DmStatusTenantCreationEnum.Running}
                                />
                            </div>
                            {/* <div className="col-md-6">
                            <Input
                                label="URL Alternativa Admin"
                                readOnly={!isInsert}
                                type="text"
                                name="UriBaseAdminAlt"
                                placeholder="https://admin-example-alt.com"
                            />
                        </div> */}
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Input
                                    label="Url Público"
                                    prefix="https://"
                                    sufix=".portalfacil.srv.br"
                                    usePrefixAndSufixAsValue
                                    readOnly={!isInsert || isUrlBaseFixed}
                                    type="text"
                                    name="UriBasePublic"
                                    placeholder="uf-municipio-publico"
                                    disabled={tenantModel?.DmCreationStatus === DmStatusTenantCreationEnum.Running}
                                />
                            </div>
                            {/* <div className="col-md-6">
                            <Input
                                label="URL Alternativa Público"
                                readOnly={!isInsert}
                                type="text"
                                name="UriBasePublicAlt"
                                placeholder="https://public-example-alt.com"
                            />
                        </div> */}
                        </div>

                        <div>
                            <label>Hosts Permitidos para responder a API(alias)</label>
                            {hostNames && (
                                <div className="row">
                                    {hostNames.map((item: string, index: number) => {
                                        return (
                                            <div className="col-md-6" key={`HostNames[${index}]`}>
                                                <Input
                                                    label={`Host ${index + 1}`}
                                                    readOnly={!isInsert}
                                                    prefix="http[s]://"
                                                    itemType={index === 0 ? 'simple' : 'removable'}
                                                    removeAction={(function (i) {
                                                        return e => removeItemHostNames(e, i)
                                                    })(index)}
                                                    type="text"
                                                    name={`HostNames[${index}]`}
                                                    defaultValue={item}
                                                    placeholder="example.com"
                                                    disabled={
                                                        tenantModel?.DmCreationStatus ===
                                                        DmStatusTenantCreationEnum.Running
                                                    }
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                            <div className="my-2">
                                <Button
                                    className="btn-icon mb-50"
                                    color="success"
                                    onClick={addItemHostNames}
                                    disabled={tenantModel?.DmCreationStatus === DmStatusTenantCreationEnum.Running}
                                >
                                    <Plus size={18} />
                                    <span className="align-middle ml-25 mb-50">Adicionar</span>
                                </Button>
                            </div>
                        </div>
                        <FormGroup>
                            <label>Hosts Permitidos para Consumir API(Cors)</label>
                            {corsAllowedOrigins && (
                                <div className="row">
                                    {corsAllowedOrigins.map((item: string, index: number) => {
                                        return (
                                            <div className="col-md-6" key={`CorsAllowedOrigins[${index}]`}>
                                                <Input
                                                    label={`Host ${index + 1}`}
                                                    readOnly={!isInsert}
                                                    prefix="http[s]://"
                                                    itemType="removable"
                                                    removeAction={(function (i) {
                                                        return e => removeItemCorsAllowedOrigins(e, i)
                                                    })(index)}
                                                    type="text"
                                                    name={`CorsAllowedOrigins[${index}]`}
                                                    defaultValue={item}
                                                    placeholder="example.com"
                                                    disabled={
                                                        tenantModel?.DmCreationStatus ===
                                                        DmStatusTenantCreationEnum.Running
                                                    }
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                            <Row>
                                <Col className="my-2">
                                    <Button
                                        className="btn-icon mb-50"
                                        color="success"
                                        disabled={tenantModel?.DmCreationStatus === DmStatusTenantCreationEnum.Running}
                                        onClick={addItemCorsAllowedOrigins}
                                    >
                                        <Plus size={18} />
                                        <span className="align-middle ml-25 mb-50">Adicionar</span>
                                    </Button>
                                </Col>
                            </Row>
                        </FormGroup>

                        <Row lg={2} className="mb-2">
                            <Col>
                                <Dropdown as={ButtonGroup}>
                                    <Button
                                        color="primary"
                                        className="btn-primary"
                                        onClick={handleSaveDraft}
                                        disabled={tenantModel?.DmCreationStatus === DmStatusTenantCreationEnum.Running}
                                    >
                                        {creationProgress.StepsCompleted.includes(
                                            TenantCreationStepsAndTasks.TenantCreationStep1
                                        )
                                            ? 'Salvar alterações'
                                            : 'Salvar Rascunho'}
                                    </Button>
                                    {!creationProgress.StepsCompleted.includes(
                                        TenantCreationStepsAndTasks.TenantCreationStep1
                                    ) && (
                                        <>
                                            <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />
                                            {(creationProgress.DmCreationStatus ===
                                                DmStatusTenantCreationEnum.Running && (
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={handleAbortCreation}>
                                                        Abortar criação
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            )) || (
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={handleSubmitCreation}>
                                                        Iniciar criação
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            )}
                                        </>
                                    )}
                                </Dropdown>
                            </Col>
                        </Row>
                    </fieldset>
                </Form>
            </Container>
        </>
    )
}

export default TenantCreationStep1
