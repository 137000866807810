import React, { useEffect, useRef } from 'react'
import ReactInputMask, { Props as InputProps } from 'react-input-mask'

import ActFormItemLayout from './form-item-layout'
import { useField } from '@unform/core'

// import { FormInputProps } from '@/types/form/input'

// interface  Props extends Omit<InputProps, 'size'>, FormInputProps {
//     name: string
// }

interface Props extends Omit<InputProps, 'size'> {
    name: string
    label?: string
    description?: string
    helpText?: string
    size?: 'default' | 'small' | 'large'
}

export default function InputMask({
    name,
    label,
    required,
    description,
    helpText,
    size,
    className,
    readOnly,
    ...rest
}: Props) {
    const inputRef = useRef(null)
    const { fieldName, registerField, defaultValue, error } = useField(name)

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            getValue: ref => {
                // return ref.current.maskValue
                const ret = ref?.getInputValue() || ''
                return ret
            },
            setValue(ref: any, value: string) {
                ref.setInputValue(value || '')
            },
            clearValue(ref: any) {
                ref.setInputValue('')
            }
        })
    }, [fieldName, registerField])

    return (
        <ActFormItemLayout {...{ name: fieldName, label, required, description, helpText }}>
            <ReactInputMask
                name={fieldName}
                ref={inputRef}
                defaultValue={defaultValue}
                className={`${className || ''}${error ? ' form-control is-invalid' : ' form-control'}`}
                disabled={readOnly}
                readOnly={readOnly}
                {...rest}
            />
        </ActFormItemLayout>
    )
}
